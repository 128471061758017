<template>
  <el-dialog title="添加委员会" v-model="view" width="500px">
    <el-form ref="form" label-width="100px" class="el-dialog-div">
      <el-form-item label="委员会名称">
        <el-input v-model="form.ComName"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确定</el-button>
                </span>
    </template>
  </el-dialog>
</template>

<script>
import {AddCommittee} from '../../../api/ProjectAdmin/Committee'

export default {
  data() {
    return {
      view: false,
      form: {},
      App_id: ''
    }
  },
  methods: {
    saveAdd() {
      let ComName = this.form.ComName
      let App_id = this.App_id
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id

      AddCommittee({ComName, App_id, Admin_id}).then(res => {
        if (res.code == 0) {
          this.$parent.FindCommittee()
          this.view = false;
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      })
    },
    open(App_id) {
      this.form = {}
      this.view = true
      this.App_id = App_id
    },
    close() {
      this.form = {}
      this.App_id = ''
    }
  }
}
</script>

<style scoped>

</style>