<template>

  <el-dialog title="委员会管理" v-model="view" width="1000px">
    <div class="title">{{ Titletext }}</div>
    <el-button type="primary" icon="el-icon-plus" @click="handladd" class="add">添加成员用户</el-button>
    <el-table
        :data="tableData"
        border
        class="table"
        header-cell-class-name="table-header"
        height="400"
    >
      <el-table-column prop="UserName" label="姓名"></el-table-column>
      <el-table-column prop="UserMobile" label="手机号码"></el-table-column>
      <el-table-column prop="Time" label="新增时间"></el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button type="text" class="red" @click="handremove(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view = false">关闭</el-button>
                </span>
    </template>
  </el-dialog>
  <ChoiceUser ref="ChoiceUser"></ChoiceUser>
</template>

<script>
import {AddUserCommittee, FindUserCommittee, RemoveUserCommittee} from '../../../api/ProjectAdmin/Committee'
import ChoiceUser from '../../../components/ChoiceUser'

export default {
  data() {
    return {
      Titletext: '',
      ComName: '',
      view: false,
      query: {
        App_id: '',
        Title: '',
        ComName: '',
      },
      tableData: []
    }
  },
  methods: {
    handremove(id) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      let App_id = this.query.App_id
      this.$confirm("是否确认删除该成员吗？", "提示", {
        type: "warning"
      }).then(() => {
        RemoveUserCommittee({id, Admin_id, App_id}).then(res => {
          if (res.code == 0) {
            this.getdata()
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    handlSelected(data) {
      let User_id = data.User_id
      let App_id = this.query.App_id
      let ComName = this.query.ComName
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id

      AddUserCommittee({User_id, App_id, ComName, Admin_id}).then(res => {
        if (res.code == 0) {
          // this.$parent.handleSearch()
          // this.view = false;
          this.getdata()
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handladd() {
      this.$refs.ChoiceUser.handlAdd()
    },
    getdata() {
      let App_id = this.query.App_id
      let ComName = this.query.ComName
      FindUserCommittee({App_id, ComName}).then(res => {
        let tableData = res.sum
        this.tableData = tableData
      })
    },
    open(App_id, Title, ComName) {
      this.view = true
      let Titletext = '社区项目：' + Title + '   委员会：' + ComName
      this.Titletext = Titletext
      this.query.App_id = App_id
      this.query.Title = Title
      this.query.ComName = ComName
      this.getdata()
    }
  },
  components: {
    ChoiceUser
  }
}
</script>

<style scoped>
.title {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 16px;
}

.add {
  margin-bottom: 20px;
}

.red {
  color: red;
}
</style>