import request from '../../utils/request';

/**
 * 委员会成员管理
 * @param query
 * @returns {AxiosPromise}
 */

//查询项目总数据
export const FindProjec = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Committee/TolObject/find',
        method: 'get',
        params: query
    });
};


//查询委员会信息
export const FindCommittee = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Committee/Committee/find',
        method: 'get',
        params: query
    });
};


//添加委员会
export const AddCommittee = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Committee/Committee/add',
        method: 'post',
        data: query
    });
};

//查询用户的委员会
export const FindUserCommittee = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Committee/Committee/User/find',
        method: 'get',
        params: query
    });
};



//查询用户的委员会
export const AddUserCommittee = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Committee/Committee/User/add',
        method: 'post',
        data: query
    });
};


//删除用户的委员会
export const RemoveUserCommittee = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Committee/Committee/User/remove',
        method: 'post',
        data: query
    });
};
