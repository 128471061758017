<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 委员会成员管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-loading="loading">
      <div class="handle-box">
        <el-select v-model="query.ProjectName" placeholder="请选择选择项目" class="handle-select mr10"
                   @change="handleSearch"
                   size="small">
          <el-option :key="index" :label="item.Title" :value="item.Title"
                     v-for="(item,index) in query.ProjecData"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-plus" @click="handladd" v-if="query.ProjectName">添加委员会</el-button>
      </div>

      <el-table
          :data="tableData"
          border
          size="small"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Title" label="社区项目昵称"></el-table-column>
        <el-table-column prop="ComName" label="委员会昵称"></el-table-column>
        <el-table-column prop="NewTime" label="添加时间"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button @click="openCommitteeAdmin(scope.row.Title,scope.row.ComName)">管理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <CommitteeAdd ref="CommitteeAdd"></CommitteeAdd>
    <CommitteeAdmin ref="CommitteeAdmin"></CommitteeAdmin>
  </div>
</template>

<script>

import CommitteeAdd from './com/CommitteeAdd'
import CommitteeAdmin from './com/CommitteeAdmin'
import {FindProjec, FindCommittee} from "../../api/ProjectAdmin/Committee";

export default {
  data() {
    return {
      loading: false,
      query: {
        ProjectName: '',
        ProjecData: [],
      },
      App_id: '',
      tableData: [],
    };
  },
  created() {
    this.findProjec()
  },
  methods: {
    openCommitteeAdmin(Title, ComName) {
      let App_id = this.App_id
      this.$refs.CommitteeAdmin.open(App_id, Title, ComName)
    },
    handladd() {
      this.$refs.CommitteeAdd.open(this.App_id)
    },
    handleSearch() {
      let ProjecData = this.query.ProjecData
      for (let item in ProjecData) {
        if (this.query.ProjectName == ProjecData[item].Title) {
          let App_id = ProjecData[item].App_id
          this.App_id = App_id
          this.FindCommittee()
          break;
        }
      }
    },
    findProjec() {
      this.loading = true
      FindProjec().then(res => {
        this.loading = false
        let ProjecData = res.sum
        this.query.ProjecData = ProjecData
        if (ProjecData.length >= 1) {
          let Title = ProjecData[0].Title
          this.query.ProjectName = Title
          this.handleSearch()
        }
      })
    },
    FindCommittee() {
      let App_id = this.App_id
      FindCommittee({App_id}).then(res => {
        this.tableData = res.sum
      })
    },

  },
  components: {
    CommitteeAdd,
    CommitteeAdmin
  }
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 200px;
  margin-right: 20px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

</style>
